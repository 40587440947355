// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-platform-redirect-tsx": () => import("./../../../src/pages/platform-redirect.tsx" /* webpackChunkName: "component---src-pages-platform-redirect-tsx" */),
  "component---src-templates-api-doc-tsx": () => import("./../../../src/templates/apiDoc.tsx" /* webpackChunkName: "component---src-templates-api-doc-tsx" */),
  "component---src-templates-api-page-tsx": () => import("./../../../src/templates/apiPage.tsx" /* webpackChunkName: "component---src-templates-api-page-tsx" */),
  "component---src-templates-doc-tsx": () => import("./../../../src/templates/doc.tsx" /* webpackChunkName: "component---src-templates-doc-tsx" */),
  "component---src-templates-platform-tsx": () => import("./../../../src/templates/platform.tsx" /* webpackChunkName: "component---src-templates-platform-tsx" */)
}

